import React from 'react' ;
import { Box, Link, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Divider } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThemedButton from '../common/ThemedButton';

const ForgotPasswordScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const forgotPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                "/api/auth/forgot-password",
                { email },
                config
            );

            setSuccess(data.data);
        } catch (error) {
            setError(error.response.data.error);
            setEmail("");
            setTimeout(() => {
                setError("");
            }, 7000);
        }
    };

    return (
        <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "2rem auto" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={forgotPasswordHandler}>
                {success && <span className="success-message" style={{color: "green"}}>{success}</span>}
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center" color="primary">Forgot Password</Typography>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:2}} required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                <ThemedButton w="100%" type="submit" text="Send Email"/>

                <Divider sx={{my:4}}></Divider>
            </form>
            <Typography mt={2} textAlign="center">Don't have an account? <Link underline="hover" href="/register"><b>Sign up</b></Link></Typography>
            <Typography mt={2} textAlign="center"> <Link underline="hover" href="/forgot-password">Forgot your password?</Link></Typography>

        </Box>
    )
}

export default ForgotPasswordScreen;