import { useState } from "react";
import { useParams} from "react-router-dom";
import axios from "axios";
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField } from '@mui/material';
import ThemedButton from "../common/ThemedButton";

const ResetPasswordScreen = () => {
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const {resetToken}  = useParams();
    console.log(resetToken)

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        if (password !== confirmPassword) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Passwords don't match");
        }

        if (password.length < 6) {
            setPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                setError("");
            }, 5000);
            return setError("Password must be at least 6 characters");
        }

        try {
            const { data } = await axios.put(
                `/api/auth/password-reset/${resetToken}`,
                {
                    password,
                },
                config
            );
                console.log("data", data)
            setSuccess(data.data);
        } catch (error) {
            if (error.response.data.error) {
                setError(error.response.data.error);
            } else if (error.message) {
            setError(error.message);
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    return (
        <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "2rem auto" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={resetPasswordHandler}>
                {success && <span className="success-message" style={{color: "green"}}>{success}</span>}
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center" color="primary">Reset Password</Typography>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:4}} required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Confirm Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { borderRadius: 10 }, mb:4}} required fullWidth value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>

                <ThemedButton w="100%" type="submit" text="Reset Password"/>
                
            </form>

        </Box>
    );
};

export default ResetPasswordScreen;