import React from 'react';
import {  Button, Stack, useMediaQuery, Drawer, List, ListItem, Popover,
    ListItemText, ListItemButton, IconButton, Box, FormControlLabel} from '@mui/material';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
//import logo from '../assets/piearm-logo.webp';
import '../screens/style.css'
import { SocketContext } from '../../SocketContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import logo from '../assets/logo.svg';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#1E3B6E',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

const Navbar = React.memo(({mode, toggleTheme}) => { 
    const socket = useContext(SocketContext);
    const location  = useLocation();
    const [isSubbed, setIsSubbed] = useState(localStorage.getItem("sub"));
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("admin") === "true");
    const [isPartner, setIsPartner] = useState(localStorage.getItem("partner") === "true");
    const [loggedIn, setLoggedIn ]= useState(localStorage.getItem("authToken"));
    const mobile = useMediaQuery(isAdmin ? "(max-width: 1080px)" : "(max-width: 920px)");
    const [profilePopover, setProfilePopover] = useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    //const [socketId, setSocketId] = useState(null);
    const [isTop, setIsTop] = useState(true);

    useEffect(() => {
        function handleScroll() {
            setIsTop(window.pageYOffset === 0);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        //console.log("locating")
        async function loadCheckRefresh (socketId) {
            await checkRefresh(socketId);
        }
        socket.on('connect', () => {
            //console.log('Connected to server');
            //setSocketId(socket.id);
            loadCheckRefresh(socket.id)
        });
    
        // Clean up the effect by removing the listener
        return () => socket.off('connect');
        
    }, [socket]);

    useEffect(() => {
        setIsSubbed(localStorage.getItem("sub"));
        setIsAdmin(localStorage.getItem("admin") === "true");
        setIsPartner(localStorage.getItem("partner") === "true");
        setLoggedIn(localStorage.getItem("authToken"));
        if (location.pathname === '/resources') {
            setIsTop(false);
        }
    }, [location]);


    const logoutHandler = async () => { 
        try {
            await axios.post("/api/auth/logout").then(res => fullyLogout(res.data));
        } catch (err) {
            console.log(err);
        }
    }

    const fullyLogout = (data) => { 
        if (data.success) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("sub");
            localStorage.removeItem("admin");
            localStorage.removeItem("partner");
            setLoggedIn(false);
            setPopoverAnchorEl(null);
            setProfilePopover(false);
            //window.location.reload();
        }
    }

    const checkRefresh = async (socketId) => { 
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data === "Unauthorized" && loggedIn) {
                localStorage.removeItem("authToken");
                localStorage.removeItem("sub");
                setLoggedIn(false);
                logoutHandler();
            } else {
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    await axios.get("/api/auth/subscription", config).then(res => checkSub(res.data));
                    await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); setIsAdmin(true)});
                    await axios.post("/api/auth/set-socket-id", { socketId: socketId }, config);
                    if (location.search.includes('?ref=')) {
                        const refCode = location.search.split('?ref=')[1];
                        const {data} = await axios.post("/api/auth/check-partner-code", { partnerCode: refCode }, config);
                        //console.log(data)
                        if (data.success) {
                            Cookies.set('ref', refCode, { expires: 7 });
                        } /*else {
                            console.log('partner code not found')
                        }*/
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    const navbarStyle = {
        backgroundColor: isTop ? 'transparent' : (mode === 'light' ? 'white' : '#1f2638'),
        boxShadow: isTop ? 'none' : '0 0 10px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.5s ease-in-out',
        position: 'fixed',
        letterSpacing: '0.5px',
        zIndex: 100
    };
    
    const checkSub = (data) => {
        if (data.subscription) {
            localStorage.setItem("sub", data.subscription);
            setIsSubbed(true)
        } else {
            localStorage.removeItem("sub");
        }
    }

    const createPortal = async () => { 
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data) {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                const customerId = await axios.get("/api/auth/customer", config);
                if (customerId.data.customerId) {
                    const portal = await axios.post("/api/stripe/portal", { customerId: customerId.data.customerId }, config);
                    if (portal.data.url) {
                        window.open(portal.data.url, "_self");
                        setPopoverAnchorEl(null);
                        setProfilePopover(false);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }


    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    


    const DrawerComponent = () => {
        const [openDrawer, setOpenDrawer] = useState(false);
        return (
            <>
            <Drawer anchor = 'right' onClose = {() => setOpenDrawer(false)} open = {openDrawer} >
                <List sx={{letterSpacing: '0.5px',}}>
                    { loggedIn ? 
                    <>
                    <Box p={1}>
                        <FormControlLabel onClick={toggleTheme}
                            control={<MaterialUISwitch sx={{ ml: 1 }} checked={mode === 'dark'}/>}
                        />
                    </Box>
                    <ListItem key={"Pricing"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/pricing">
                            <ListItemText primary={"Pricing"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem divider key={"Dashboard"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/dashboard">
                            <ListItemText primary={"Dashboard"} />
                        </ListItemButton>
                    </ListItem>
                    
                    {isSubbed &&
                    <ListItem key={"Billing Portal"} disablePadding>
                        <ListItemButton onClick={createPortal}>
                            <ListItemText primary={"Billing Portal"} />
                        </ListItemButton>
                    </ListItem>
                    }

                    <ListItem key={"Saves"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/saves">
                            <ListItemText primary={"Saves"} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={"Shares"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/shares">
                            <ListItemText primary={"Shares"} />
                        </ListItemButton>
                    </ListItem>

                    {isAdmin &&
                    <ListItem key={"Admin Portal"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/admin">
                            <ListItemText primary={"Admin Portal"} />
                        </ListItemButton>
                    </ListItem>
                    }

                    {isPartner &&
                    <ListItem key={"Partner Portal"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/partner">
                            <ListItemText primary={"Partner Portal"} />
                        </ListItemButton>
                    </ListItem>
                    }

                    <ListItem key={"Logout"} disablePadding>
                    <ListItemButton onClick={logoutHandler}>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                    </ListItem>
                    </>
                    :
                    <>
                    <ListItem divider key={"Pricing"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/pricing">
                            <ListItemText primary={"Pricing"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Register"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/register">
                            <ListItemText primary={"Register"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Sign in"} disablePadding>
                        <ListItemButton component={ReactRouterLink} to="/login">
                            <ListItemText primary={"Sign in"} />
                        </ListItemButton>
                    </ListItem>
                    </>
                    }
                </List>
            </Drawer>
            <IconButton onClick = {() => setOpenDrawer(!openDrawer)} sx={{ml:'auto'}}>
                <MenuIcon sx={{color:'inherit', fontSize:'30px'}}/>
            </IconButton>
            </>
        )
      }

      return (
        <>
        { !mobile ? 
        <header style={navbarStyle}>
            <Stack direction={'row'} px='5vw' py={2}>
                <Stack spacing={2} justifyContent={"left"} alignItems="center">
                <Box mt={0} sx={{":hover": {cursor: 'pointer'}}} component={ReactRouterLink} to="/"><img src={logo} alt="logo" width="240px" height="auto"/></Box>
                </Stack>
                { loggedIn ? 
                    <Stack width="100%" direction="row" spacing={2} justifyContent={"right"} alignItems="center">
                        {/*<Button onClick={handleClick} >
                            Marketing Tools
                        </Button>*/}
                        {localStorage.getItem("admin") === "true" &&
                            <Button component={ReactRouterLink} to="/admin" sx={{borderRadius: 10}}>
                                Admin Portal
                            </Button>
                        }
                        {localStorage.getItem("partner") === "true" &&
                            <Button component={ReactRouterLink} to="/partner" sx={{borderRadius: 10}}>
                                Partner Portal
                            </Button>
                        }
                        <Button component={ReactRouterLink} to="dashboard" sx={{borderRadius: 10}}>
                            Dashboard
                        </Button>
                        <Button component={ReactRouterLink} to="/pricing" sx={{ borderRadius: 10}}>
                            Pricing
                        </Button>

                        <FormControlLabel onClick={toggleTheme}
                            control={<MaterialUISwitch sx={{ ml: 1 }} checked={mode === 'dark'}/>}
                        />
                        
                        <IconButton onClick={(e) => {setPopoverAnchorEl(e.currentTarget); setProfilePopover(true)}}>
                            <AccountCircleIcon sx={{color: "primary.main", fontSize: 34}}/>
                        </IconButton>
                        <Popover open={profilePopover}
                            anchorEl={popoverAnchorEl}
                            onClose={()=>{ setPopoverAnchorEl(null); setProfilePopover(false)} }
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                        >
                            <List sx={{bgcolor: mode === 'light' ? 'white' : '#111418'}}>
                                <ListItem key={"Saves"} disablePadding>
                                    <ListItemButton component={ReactRouterLink} to="/saves" onClick={()=>{setPopoverAnchorEl(null); setProfilePopover(false);}}>
                                        <ListItemText primary={"Saves"} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem key={"Shares"} disablePadding>
                                    <ListItemButton component={ReactRouterLink} to="/shares" onClick={()=>{setPopoverAnchorEl(null); setProfilePopover(false);}}>
                                        <ListItemText primary={"Shares"} />
                                    </ListItemButton>
                                </ListItem>
                                {isSubbed &&
                                <ListItem key={"Billing Portal"} disablePadding>
                                    <ListItemButton onClick={createPortal}>
                                        <ListItemText primary={"Billing Portal"} />
                                    </ListItemButton>
                                </ListItem>
                                }
                                <ListItem key={"Logout"} disablePadding>
                                    <ListItemButton onClick={logoutHandler}>
                                        <ListItemText primary={"Logout"} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Popover>
                    </Stack> 
                    : 
                        <Stack width="100%" direction="row" spacing={2} justifyContent={"right"} alignItems="center">
                            <Button component={ReactRouterLink} to="/pricing" sx={{borderRadius: 10}}>
                                Pricing
                            </Button>
                            <Button component={ReactRouterLink} to="/register" sx={{borderRadius: 10}}>
                             Register
                            </Button>
                            <Button component={ReactRouterLink} to="/login" variant="contained" p={1} disableElevation={true}
                            sx={{textTransform: 'none', borderRadius: 10, fontSize: 16, width: 110, fontWeight: 700}}>
                                Login
                            </Button>
                        </Stack>
                }
            </Stack>
        </header>
        :
        <header style={navbarStyle}>
            <div className="container header-container" >
                <Box mt={1} sx={{":hover": {cursor: 'pointer'}}}  component={ReactRouterLink} to="/"><img src={logo} alt="logo" width="170px"/></Box>
                <DrawerComponent/>
            </div>
        </header>
        }
        </>
      )
})

export default Navbar;